import React from "react";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row } from "react-bootstrap";
import { Link } from "gatsby";
import { SafeHtmlParser } from "./safeHtmlParser";

const ImageSide = ({
  title,
  image,
  imageAlt,
  order,
  text,
  imagePadding,
  ctaButton,
  centerButton,
}) => {
  return (
    <section className="py-5 py-xl-0 bg-white">
      <Container fluid className="px-0 mx-0">
        <Row className="px-0 mx-0 align-items-center">
          <Col
            className={`mx-0 ${imagePadding}`}
            xs={{ span: 12, order: "first" }}
            lg={{ span: 6, order: order }}
          >
            <GatsbyImage className="w-100" image={image} alt={imageAlt} />
          </Col>
          <Col className="px-3 px-lg-5" xs={12} lg={6}>
            <h2 className="pb-4">{title}</h2>
            <SafeHtmlParser htmlContent={text} />
            {ctaButton && ctaButton.url && (
              <div className={centerButton ? "text-center": "text-start"}>
                <a
                  style={{ borderRadius: "0px" }}
                  variant="secondary"
                  as={Link}
                  href={ctaButton.url}
                  className="px-5 py-3 mt-3 mb-4 w-100 w-md-auto mb-md-0 me-4 btn btn-secondary"
                >
                  {ctaButton.title}
                </a>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ImageSide;
