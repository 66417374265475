import { Link, useStaticQuery, graphql } from "gatsby";
import { v4 as uuidv4 } from "uuid";
import React, { useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import HomeHero from "../components/home-hero";
import ImageSide from "../components/image-side";
import Badges from "../components/badges";
import Reviews from "../components/reviews";
import CountUp from "react-countup";
import useOnScreen from "../components/use-on-screen";
import "react-alice-carousel/lib/alice-carousel.css";
import MobileServiceCard from "../components/mobile-service-card";
import { Button } from "react-bootstrap";
import MotForm from "../components/mot-form";
import { checkPropertiesForNull } from "../../utils";
import { SafeHtmlParser } from "../components/safeHtmlParser";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "homepage" }) {
        homepageFields {
          homeBanner {
            homeBannerHeading
            homeBannerBackgroundVideo {
              node {
                mediaItemUrl
              }
            }
            homeBannerButton {
              title
              target
              url
            }
          }
          threeColumnServices {
            ecfServices {
              serviceHeading
              serviceUrl {
                target
                title
                url
              }
              serviceIcon {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
          twoColumnImageContent {
            twoColumnHeading
            twoColumnContent
            twoColumnButton {
              target
              title
              url
            }
            twoColumnImage {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          approvedVehicleLogoSlider {
            sliderHeading
            sliderBottomNotice
            sliderLogos {
              nodes {
                ... on WpVehicleLogo {
                  vehicleLogoFields {
                    logoUrl
                    logoImage {
                      node {
                        altText
                        localFile {
                          publicURL
                          childImageSharp {
                            gatsbyImageData(
                              formats: [AUTO, WEBP]
                              quality: 100
                              transformOptions: {
                                cropFocus: CENTER
                                fit: CONTAIN
                              }
                              layout: CONSTRAINED
                              placeholder: BLURRED
                            )
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          parallaxImageSection {
            parallaxImage {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          ecfCareNumbersSection {
            ecfCareNumbers {
              ecfCareNumberHeading
              ecfCareNumberText
            }
            ecfCareSectionHeading
            ecfCareLeftContent
            ecfCareRightContent
            ecfCareSectionButton {
              title
              target
              url
            }
          }
          reviewsSection {
            reviewsSectionHeading
          }
          twoColumnGridSection {
            twoColumnSections {
              twoColumnSectionHeading
              twoColumnSectionContent
              twoColumnSectionImage {
                node {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
            }
            twoColumnSectionsButton {
              title
              target
              url
            }
          }
          leftImageRightContent {
            rightHeading
            rightContent
            leftImage {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          backgroundImageContent {
            backgroundImageHeading
            backgroundImageContentText
            backgroundImageContentButton {
              title
              target
              url
            }
            sectionBackgroundImage {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          twoColumnFormContent {
            twoColumnFormHeading
            twoColumnFormText
            twoColumnFormButton {
              title
              target
              url
            }
          }
          backgroundImageOverlayContent {
            backgroundImageOverlayHeading
            backgroundImageOverlayContentText
            backgroundImageOverlayLink {
              title
              target
              url
            }
            backgroundImageWithOverlay {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          headingWithTwoColumnContent {
            headingWithTwoColumn
            twoColumnLeftContent
            twoColumnRightContent
          }
          videoSection {
            uploadVideoFile {
              node {
                mediaItemUrl
                title
              }
            }
          }
        }
        seoFields {
          title
          opengraphTitle
          opengraphDescription
          metaDescription
          localBusinessSchema
          image {
            node {
              altText
              localFile {
                childImageSharp {
                  original {
                    height
                    width
                  }
                }
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFields, homepageFields },
  } = data;

  const {
    homeBanner,
    threeColumnServices,
    twoColumnImageContent,
    approvedVehicleLogoSlider,
    parallaxImageSection,
    ecfCareNumbersSection,
    reviewsSection,
    twoColumnGridSection,
    leftImageRightContent,
    backgroundImageContent,
    twoColumnFormContent,
    backgroundImageOverlayContent,
    headingWithTwoColumnContent,
    videoSection,
  } = homepageFields;

  const siteUrl = data.site.siteMetadata.siteUrl;
  const ref1 = useRef();
  const onScreen1 = useOnScreen(ref1, "0px");

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
        <script type="application/ld+json">
          {JSON.stringify(seoFields?.localBusinessSchema)}
        </script>
      </Helmet>
      <LocalBusinessSchema />

      <GatsbySeo
        title={seoFields?.title}
        description={seoFields?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFields?.opengraphTitle}`,
          description: `${seoFields?.opengraphDescription}`,
          images: [
            {
              url: `${seoFields?.image?.node.localFile.publicURL}`,
              width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFields?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {homeBanner && (
          <HomeHero
            title={homeBanner.homeBannerHeading}
            video={homeBanner.homeBannerBackgroundVideo?.node?.mediaItemUrl}
            ctaButton={homeBanner.homeBannerButton}
          />
        )}

        <section className="pt-5 bg-white py-lg-8 ">
          <Container className="d-lg-none ">
            {threeColumnServices.ecfServices &&
              threeColumnServices.ecfServices.length > 0 && (
                <Row>
                  {threeColumnServices.ecfServices.map((item, i) => (
                    <MobileServiceCard
                      title={item.serviceHeading}
                      url={item.serviceUrl?.url}
                      key={i}
                      svg={
                        <GatsbyImage
                          image={
                            item.serviceIcon?.node?.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          alt={item.serviceIcon?.node?.altText}
                          style={{ width: "30px", height: "auto" }}
                        />
                      }
                    />
                  ))}
                </Row>
              )}
          </Container>
          {threeColumnServices.ecfServices &&
            threeColumnServices.ecfServices.length > 0 && (
              <Container className="d-none d-lg-block">
                <Row className="align-items-center ">
                  {threeColumnServices.ecfServices.map((item, i) => (
                    <Col
                      className="pb-5 text-center py-lg-3 hp-services-middle"
                      lg={4}
                      key={i}
                    >
                      <GatsbyImage
                        image={
                          item.serviceIcon?.node?.localFile.childImageSharp
                            .gatsbyImageData
                        }
                        alt={item.serviceIcon?.node?.altText}
                        style={{ width: "30px", height: "auto" }}
                      />
                      <Link
                        to={item.serviceUrl?.url}
                        className="pt-3 fs-2 d-block inter-bold black-link"
                      >
                        {item.serviceHeading}
                      </Link>
                    </Col>
                  ))}
                </Row>
              </Container>
            )}
        </section>
        {twoColumnImageContent &&
          !checkPropertiesForNull(twoColumnImageContent, [
            "twoColumnHeading",
          ]) && (
            <ImageSide
              image={
                twoColumnImageContent.twoColumnImage?.node?.localFile
                  .childImageSharp.gatsbyImageData
              }
              imageAlt={twoColumnImageContent.twoColumnImage?.node?.altText}
              title={twoColumnImageContent.twoColumnHeading}
              order="first"
              imagePadding="px-0 pb-5 pb-lg-0"
              text={twoColumnImageContent.twoColumnContent}
              ctaButton={twoColumnImageContent?.twoColumnButton}
            />
          )}
        {approvedVehicleLogoSlider &&
          !checkPropertiesForNull(approvedVehicleLogoSlider, [
            "sliderHeading",
          ]) && (
            <Badges
              title={approvedVehicleLogoSlider.sliderHeading}
              text={approvedVehicleLogoSlider.sliderBottomNotice}
              logos={approvedVehicleLogoSlider.sliderLogos?.nodes}
            />
          )}
        {parallaxImageSection?.parallaxImage && (
          <>
            <section className="vh-100 d-none d-xl-block ">
              <GatsbyImage
                className="vh-100 position-fixed"
                style={{ top: "-12vh", zIndex: "-9999" }}
                image={
                  parallaxImageSection?.parallaxImage?.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                alt={parallaxImageSection?.parallaxImage?.node?.altText}
              />
            </section>
            <section className=" d-xl-none">
              <GatsbyImage
                className="w-100 position-fixed"
                style={{ top: "12vh", zIndex: "-9999" }}
                image={
                  parallaxImageSection?.parallaxImage?.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                alt={parallaxImageSection?.parallaxImage?.node?.altText}
              />
              <GatsbyImage
                className="w-100 "
                style={{ zIndex: "-9999", opacity: 0 }}
                image={
                  parallaxImageSection?.parallaxImage?.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                alt={parallaxImageSection?.parallaxImage?.node?.altText}
              />
            </section>
          </>
        )}

        {ecfCareNumbersSection &&
          !checkPropertiesForNull(ecfCareNumbersSection, [
            "ecfCareSectionHeading",
          ]) && (
            <section className="py-5 bg-white pt-lg-8">
              <Container>
                {ecfCareNumbersSection.ecfCareNumbers &&
                  ecfCareNumbersSection.ecfCareNumbers.length > 0 && (
                    <Row ref={ref1}>
                      {ecfCareNumbersSection.ecfCareNumbers.map(
                        (careItem, i) => (
                          <Col
                            className="text-center pb-5 pb-lg-0"
                            xs={
                              i + 1 !==
                              ecfCareNumbersSection.ecfCareNumbers.length
                                ? 6
                                : ""
                            }
                            lg={4}
                            key={uuidv4()}
                          >
                            <h2 className="fs-4 inter-light">
                              {careItem.ecfCareNumberHeading}
                            </h2>
                            <p className="inter-bold display-2">
                              {onScreen1 && (
                                <CountUp end={careItem.ecfCareNumberText} />
                              )}
                            </p>
                          </Col>
                        )
                      )}
                    </Row>
                  )}

                <Row>
                  <Col className="py-5 pt-lg-7">
                    <h2 className="text-center">
                      {ecfCareNumbersSection.ecfCareSectionHeading}
                    </h2>
                  </Col>
                </Row>
                <Row>
                  {ecfCareNumbersSection.ecfCareLeftContent && (
                    <Col lg={6}>
                      <SafeHtmlParser
                        htmlContent={ecfCareNumbersSection?.ecfCareLeftContent}
                      />
                    </Col>
                  )}

                  {ecfCareNumbersSection.ecfCareRightContent && (
                    <Col lg={6}>
                      <SafeHtmlParser
                        htmlContent={ecfCareNumbersSection?.ecfCareRightContent}
                      />
                    </Col>
                  )}
                </Row>
                {ecfCareNumbersSection.ecfCareSectionButton &&
                  ecfCareNumbersSection.ecfCareSectionButton.url && (
                    <Row>
                      <Col className="mt-5 text-center">
                        <Button
                          style={{ borderRadius: "0px" }}
                          variant="secondary"
                          as={Link}
                          to={ecfCareNumbersSection.ecfCareSectionButton.url}
                          className="px-5 py-3 mb-4 w-100 w-md-auto mb-md-0 me-4"
                          target={
                            ecfCareNumbersSection.ecfCareSectionButton.target ??
                            "_self"
                          }
                        >
                          {ecfCareNumbersSection.ecfCareSectionButton.title}
                        </Button>
                      </Col>
                    </Row>
                  )}
              </Container>
            </section>
          )}

        <section>
          <Reviews title={reviewsSection?.reviewsSectionHeading} />
        </section>

        {twoColumnGridSection && twoColumnGridSection.twoColumnSections && (
          <section className="py-5 py-lg-8 gradient">
            <Container>
              <Row className="justify-content-between gx-6">
                {twoColumnGridSection.twoColumnSections.map(
                  (twoColumnItem, i) => (
                    <Col
                      className={
                        i + 1 === twoColumnGridSection.twoColumnSections.length
                          ? "pt-5 pt-lg-0"
                          : ""
                      }
                      lg={6}
                      key={uuidv4()}
                    >
                      <GatsbyImage
                        image={
                          twoColumnItem.twoColumnSectionImage.node?.localFile
                            .childImageSharp.gatsbyImageData
                        }
                        alt={twoColumnItem.twoColumnSectionImage.node?.altText}
                        className="gradient-image"
                      />
                      <h2 className="my-5 text-center text-white">
                        <SafeHtmlParser
                          htmlContent={twoColumnItem?.twoColumnSectionHeading}
                        />
                      </h2>
                      <div className="text-white">
                        <SafeHtmlParser
                          htmlContent={twoColumnItem?.twoColumnSectionContent}
                        />
                      </div>
                    </Col>
                  )
                )}
              </Row>
              {twoColumnGridSection.twoColumnSectionsButton &&
                twoColumnGridSection.twoColumnSectionsButton.url && (
                  <Row>
                    <Col className="mt-5 text-center">
                      <Button
                        style={{ borderRadius: "0px" }}
                        variant="secondary"
                        as={Link}
                        to={twoColumnGridSection.twoColumnSectionsButton.url}
                        className="px-5 py-3 mb-4 w-100 w-md-auto mb-md-0 me-4"
                        target={
                          twoColumnGridSection.twoColumnSectionsButton.target ??
                          "_self"
                        }
                      >
                        {twoColumnGridSection.twoColumnSectionsButton.title}
                      </Button>
                    </Col>
                  </Row>
                )}
            </Container>
          </section>
        )}

        {leftImageRightContent && (
          <ImageSide
            image={
              leftImageRightContent.leftImage?.node?.localFile.childImageSharp
                .gatsbyImageData
            }
            imageAlt={leftImageRightContent.leftImage?.node?.altText}
            title={leftImageRightContent.rightHeading}
            order="first"
            imagePadding="p-7"
            text={leftImageRightContent.rightContent}
          />
        )}
        {backgroundImageContent &&
          !checkPropertiesForNull(backgroundImageContent, [
            "backgroundImageHeading",
          ]) && (
            <section>
              <BgImage
                style={{ backgroundPosition: "top" }}
                alt={
                  backgroundImageContent.sectionBackgroundImage.node?.altText
                }
                image={getImage(
                  backgroundImageContent.sectionBackgroundImage.node?.localFile
                    .childImageSharp.gatsbyImageData
                )}
              >
                <div className="pt-5 pb-5 position-relative pt-xl-10">
                  <div
                    style={{
                      zIndex: 1,
                      opacity: 0.5,
                      backgroundColor: "black",
                    }}
                    className="bottom-0 position-absolute h-90 h-md-80 h-xl-70 w-100"
                  ></div>
                  <Container
                    style={{ zIndex: 2 }}
                    className="pt-5 mt-5 position-relative pt-xl-10 mt-xl-10"
                  >
                    <Row>
                      <Col>
                        <h2 className="text-white">
                          {backgroundImageContent.backgroundImageHeading}
                        </h2>
                        <div className="text-white">
                          <SafeHtmlParser
                            htmlContent={
                              backgroundImageContent?.backgroundImageContentText
                            }
                          />
                        </div>
                        {backgroundImageContent.backgroundImageContentButton &&
                          backgroundImageContent.backgroundImageContentButton
                            .url && (
                            <Button
                              style={{ borderRadius: "0px" }}
                              variant="secondary"
                              as={Link}
                              to={
                                backgroundImageContent
                                  .backgroundImageContentButton.url
                              }
                              className="px-5 py-3 mt-3 mb-4 w-100 w-md-auto mb-md-0 me-4"
                              target={
                                backgroundImageContent
                                  .backgroundImageContentButton.target ??
                                "_self"
                              }
                            >
                              {
                                backgroundImageContent
                                  .backgroundImageContentButton.title
                              }
                            </Button>
                          )}
                      </Col>
                    </Row>
                  </Container>
                </div>
              </BgImage>
            </section>
          )}

        {twoColumnFormContent &&
          !checkPropertiesForNull(twoColumnFormContent, [
            "twoColumnFormHeading",
          ]) && (
            <section className="py-5 bg-white py-lg-8">
              <Container className="h-100">
                <Row
                  style={{ minHeight: "100%" }}
                  className=" gy-5 gy-lg-0 gx-xl-8"
                >
                  <Col className="h-100" lg={6}>
                    <h2 className="mb-4 ">
                      {twoColumnFormContent.twoColumnFormHeading}
                    </h2>

                    <SafeHtmlParser
                      htmlContent={twoColumnFormContent?.twoColumnFormText}
                    />
                    {twoColumnFormContent.twoColumnFormButton &&
                      twoColumnFormContent.twoColumnFormButton.url && (
                        <Button
                          style={{ borderRadius: "0px" }}
                          variant="secondary"
                          as={Link}
                          to={twoColumnFormContent.twoColumnFormButton.url}
                          className="px-5 py-3 mt-3 mb-4 w-100 w-md-auto mb-md-0 me-4"
                          target={
                            twoColumnFormContent.twoColumnFormButton.target ??
                            "_self"
                          }
                        >
                          {twoColumnFormContent.twoColumnFormButton.title}
                        </Button>
                      )}
                  </Col>
                  <Col style={{ minHeight: "100%" }} lg={6} className="">
                    <div
                      style={{ background: "#F9F9F9", borderRadius: "18px" }}
                      className="p-4 p-md-5"
                    >
                      <MotForm page="Home Page" />
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          )}

        {backgroundImageOverlayContent &&
          !checkPropertiesForNull(backgroundImageOverlayContent, [
            "backgroundImageOverlayHeading",
          ]) && (
            <section>
              {" "}
              <BgImage
                style={{ backgroundPosition: "bottom" }}
                alt={
                  backgroundImageOverlayContent.backgroundImageWithOverlay.node
                    .altText
                }
                image={getImage(
                  backgroundImageOverlayContent.backgroundImageWithOverlay.node
                    ?.localFile.childImageSharp.gatsbyImageData
                )}
              >
                <Container
                  style={{ zIndex: 2 }}
                  className="py-5 position-relative py-xl-10 "
                >
                  <Row className="justify-content-end">
                    <Col xl={5} lg={7} className="">
                      <div className="p-5 position-relative">
                        <div
                          style={{
                            zIndex: 1,
                            opacity: 0.6,
                            backgroundColor: "black",
                          }}
                          className="bottom-0 position-absolute h-100 w-100 start-0"
                        ></div>
                        <div
                          className="position-relative"
                          style={{ zIndex: 2 }}
                        >
                          <h2 className="pb-4 text-white">
                            {
                              backgroundImageOverlayContent.backgroundImageOverlayHeading
                            }
                          </h2>
                          <div className="text-white">
                            <SafeHtmlParser
                              htmlContent={
                                backgroundImageOverlayContent?.backgroundImageOverlayContentText
                              }
                            />
                          </div>
                          {backgroundImageOverlayContent.backgroundImageOverlayLink &&
                            backgroundImageOverlayContent
                              .backgroundImageOverlayLink.url && (
                              <Link
                                to={
                                  backgroundImageOverlayContent
                                    .backgroundImageOverlayLink.url
                                }
                                className="inter-bold white-link"
                              >
                                {
                                  backgroundImageOverlayContent
                                    .backgroundImageOverlayLink.title
                                }
                              </Link>
                            )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </BgImage>
            </section>
          )}
        {headingWithTwoColumnContent &&
          !checkPropertiesForNull(headingWithTwoColumnContent, [
            "headingWithTwoColumn",
          ]) && (
            <section className="py-5 bg-white py-lg-8 ">
              <Container>
                <Row>
                  <Col>
                    <h2 className="pb-5 text-center">
                      {headingWithTwoColumnContent.headingWithTwoColumn}
                    </h2>
                  </Col>
                </Row>
                <Row className=" gx-6">
                  <Col lg={6}>
                    <SafeHtmlParser
                      htmlContent={
                        headingWithTwoColumnContent?.twoColumnLeftContent
                      }
                    />
                  </Col>
                  <Col lg={6}>
                    <SafeHtmlParser
                      htmlContent={
                        headingWithTwoColumnContent?.twoColumnRightContent
                      }
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        {videoSection &&
          !checkPropertiesForNull(videoSection, ["uploadVideoFile"]) && (
            <section className="bg-white">
              <Container className="pb-5 bg-white pb-lg-7">
                <Row>
                  <Col>
                    <div>
                      <video style={{ width: "100%" }} controls>
                        <source
                          src={videoSection.uploadVideoFile?.node?.mediaItemUrl}
                          type="video/mp4"
                          title={videoSection.uploadVideoFile?.node?.title}
                        />
                        Sorry, your browser doesn't support videos.
                      </video>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          )}
      </Layout>
    </>
  );
};

export default IndexPage;
